<template>
  <div>
    <ejs-dialog
      ref="webMemberInfoPopupDialog"
      header="웹회원정보 등록"
      :animationSettings="animationSettings"
      allowDragging="true"
      showCloseIcon="true"
      width="500"
      :close="dialogClose"
      v-on:mousedown.native="onDialogClicked"
      :isModal="true"
      :cssClass="
        newFlag
          ? $t('className.popup.productCodePopupAdd')
          : $t('className.popup.productCodePopupModify')
      "
    >
      <div class="window webMemberInformationRegistration">
        <div class="windowContent">
          <div class="content-wrapper">
            <div class="content-body">
              <article class="body-article">
                <!-- 아코디언 : accordion / 닫힘 : close -->
                <section class="article-section section-01">
                  <div class="section-body">
                    <div class="body-data">
                      <div class="data-outer">
                        <div class="data-inner">
                          <ul class="data-content">
                            <li class="field memberName">
                              <!-- 필수 : required -->
                              <div class="title required">회원명</div>
                              <div class="content">
                                <ul class="row">
                                  <li class="item form-group input">
                                    <div class="form">
                                      <input-text
                                        ref="nameInput"
                                        v-model="webMembershipInfo.member.name"
                                      />
                                    </div>
                                  </li>
                                </ul>
                              </div>
                            </li>
                            <li class="field">
                              <!-- 필수 : required -->
                              <div class="title required">핸드폰</div>
                              <div class="content">
                                <ul class="row">
                                  <li class="item form-group">
                                    <div
                                      class="form"
                                      v-if="commonMixinHasCiperTextGet"
                                    >
                                      <component-telephone
                                        ref="phoneNumberInput"
                                        v-model="
                                          webMembershipInfo.member.phoneNumber
                                        "
                                        :max-length="11"
                                        @blur="onHpNoBlur"
                                      />
                                    </div>
                                    <template v-else>
                                      {{ webMembershipInfo.member.phoneNumber }}
                                    </template>
                                  </li>
                                </ul>
                              </div>
                            </li>
                            <li class="field" v-if="memberId && membershipId">
                              <!-- 필수 : required -->
                              <div class="title required">웹 ID</div>
                              <div class="content">
                                <ul class="row">
                                  <li class="item form-group">
                                    <div class="form">
                                      <input-text
                                        ref="webIdInput"
                                        :disabled="!newFlag"
                                        @blur="onWebIdBlur"
                                        v-model="displayWebId"
                                      />
                                    </div>
                                  </li>
                                </ul>
                              </div>
                            </li>
                            <li class="field" v-else>
                              <!-- 필수 : required -->
                              <div class="title required">웹 ID</div>
                              <div class="content">
                                <ul class="row">
                                  <li class="item form-group">
                                    <div class="form">
                                      <input-text
                                        ref="webIdInput"
                                        :disabled="!newFlag"
                                        @blur="onWebIdBlur"
                                        v-model="
                                          webMembershipInfo.member
                                            .webIdInformation.id
                                        "
                                      />
                                    </div>
                                  </li>
                                </ul>
                              </div>
                            </li>
                            <li class="field password">
                              <!-- 필수 : required -->
                              <div class="title">비밀번호</div>
                              <div class="content">
                                <ul class="row">
                                  <li
                                    class="item form-group input"
                                    v-if="newFlag"
                                  >
                                    <div class="form">
                                      <input
                                        id="webPasswordInput"
                                        name="webPasswordInput"
                                        v-model="
                                          webMembershipInfo.member
                                            .webIdInformation.password
                                        "
                                        class="e-input"
                                        type="password"
                                        placeholder="******"
                                        maxlength="50"
                                        autocomplete="off"
                                      />
                                    </div>
                                  </li>
                                  <li
                                    class="item form-group check"
                                    v-if="newFlag"
                                  >
                                    <!-- 포커스 : focus -->
                                    <ul class="check">
                                      <li>
                                        <label>
                                          <input
                                            type="checkbox"
                                            v-model="isSendSMS"
                                          />
                                          <i></i>
                                          <div class="label">SMS 전송</div>
                                        </label>
                                      </li>
                                    </ul>
                                  </li>
                                  <li
                                    class="item form-group button"
                                    v-if="!newFlag"
                                  >
                                    <ul class="button">
                                      <li>
                                        <erp-button
                                            button-div="GET"
                                            :is-icon-custom="true"
                                            v-on:click.native="onWebPasswordResetPopupOpen()"
                                        >
                                          재발급
                                        </erp-button>
                                      </li>
                                    </ul>
                                  </li>
                                </ul>
                              </div>
                            </li>
                            <li class="field">
                              <!-- 필수 : required -->
                              <div class="title">이메일</div>
                              <div class="content">
                                <ul class="row">
                                  <li class="item form-group">
                                    <div
                                      class="form"
                                      v-if="commonMixinHasCiperTextGet"
                                    >
                                      <input-text
                                        ref="emailInput"
                                        name="emailInput"
                                        v-model="
                                          webMembershipInfo.member.details.email
                                        "
                                      />
                                    </div>
                                    <template v-else>
                                      {{
                                        webMembershipInfo.member.details.email
                                      }}
                                    </template>
                                  </li>
                                </ul>
                              </div>
                            </li>
                            <li class="field">
                              <!-- 필수 : required -->
                              <div class="title required">생년월일</div>
                              <div class="content">
                                <ul class="row">
                                  <li class="item form-group birthday">
                                    <div class="form">
                                      <input-birthday
                                        ref="inputBirthday"
                                        type="body-data"
                                        v-model="
                                          webMembershipInfo.member.birthday
                                        "
                                      />
                                    </div>
                                  </li>
                                  <li class="item form-group check">
                                    <!-- 포커스 : focus -->
                                    <ul class="check">
                                      <li>
                                        <label>
                                          <input
                                            type="radio"
                                            v-model="
                                              webMembershipInfo.member.lunarCode
                                            "
                                            name="lunarCode"
                                            value="SOLAR"
                                          />
                                          <i></i>
                                          <div class="label">양력</div>
                                        </label>
                                      </li>
                                      <li>
                                        <label>
                                          <input
                                            type="radio"
                                            v-model="
                                              webMembershipInfo.member.lunarCode
                                            "
                                            name="lunarCode"
                                            value="LUNAR"
                                          />
                                          <i></i>
                                          <div class="label">음력</div>
                                        </label>
                                      </li>
                                    </ul>
                                  </li>
                                </ul>
                              </div>
                            </li>
                            <li class="field">
                              <!-- 필수 : required -->
                              <div class="title required">성별</div>
                              <div class="content">
                                <ul class="row">
                                  <li class="item form-group">
                                    <div class="form">
                                      <ejs-dropdownlist
                                        ref="genderDropdown"
                                        v-model="
                                          webMembershipInfo.member.gender
                                        "
                                        :dataSource="
                                          webMemberInfoOptions.genderOptions
                                        "
                                        :allowFiltering="false"
                                        :fields="commonCodeFields"
                                        cssClass="body-data-dropdown"
                                      ></ejs-dropdownlist>
                                    </div>
                                  </li>
                                </ul>
                              </div>
                            </li>
                            <li class="field">
                              <!-- 필수 : required -->
                              <div class="title">SMS 수신</div>
                              <div class="content">
                                <ul class="row">
                                  <li class="item form-group">
                                    <div class="form">
                                      <ejs-dropdownlist
                                        ref="smsReceiptDivisionDropdown"
                                        name="smsReceiptDivisionDropdown"
                                        v-model="
                                          webMembershipInfo.member
                                            .smsReceiptDivision
                                        "
                                        :dataSource="
                                          webMemberInfoOptions.smsReceiptDivisionOptions
                                        "
                                        :allowFiltering="false"
                                        :fields="commonCodeFields"
                                        cssClass="body-data-dropdown"
                                      ></ejs-dropdownlist>
                                    </div>
                                  </li>
                                </ul>
                              </div>
                            </li>
                            <li class="field">
                              <!-- 필수 : required -->
                              <div class="title">이메일 수신</div>
                              <div class="content">
                                <ul class="row">
                                  <li class="item form-group">
                                    <!-- 포커스 : focus -->
                                    <ul class="check">
                                      <li>
                                        <label>
                                          <input
                                            type="radio"
                                            v-model="
                                              webMembershipInfo.member.details
                                                .allowReceiptEmail
                                            "
                                            name="allowReceiptEmail"
                                            value="true"
                                          />
                                          <i></i>
                                          <div class="label">동의</div>
                                        </label>
                                      </li>
                                      <li>
                                        <label>
                                          <input
                                            type="radio"
                                            v-model="
                                              webMembershipInfo.member.details
                                                .allowReceiptEmail
                                            "
                                            name="allowReceiptEmail"
                                            value="false"
                                          />
                                          <i></i>
                                          <div class="label">불가</div>
                                        </label>
                                      </li>
                                    </ul>
                                  </li>
                                </ul>
                              </div>
                            </li>
                            <li class="field">
                              <!-- 필수 : required -->
                              <div class="title required">우편번호</div>
                              <div class="content">
                                <ul class="row">
                                  <li class="item form-group post">
                                    <div class="form">
                                      <input-text
                                        id="homePostalCodeInput"
                                        name="homePostalCodeInput"
                                        ref="homePostalCodeInput"
                                        v-model="
                                          webMembershipInfo.member.details
                                            .homePostalCode
                                        "
                                        maxlength="10"
                                      />
                                    </div>
                                  </li>
                                  <li class="item form-group button">
                                    <ul class="button">
                                      <li>
                                        <erp-button
                                            button-div="GET"
                                            :is-icon-custom="true"
                                            @click.native="onPostCodeClick()"
                                        >
                                          우편번호
                                        </erp-button>
                                      </li>
                                    </ul>
                                  </li>
                                </ul>
                              </div>
                            </li>
                            <li class="field">
                              <!-- 필수 : required -->
                              <div class="title required">기본주소</div>
                              <div class="content">
                                <ul class="row">
                                  <li class="item form-group">
                                    <div class="form">
                                      <input-text
                                        id="homeAddressInput"
                                        name="homeAddressInput"
                                        ref="homeAddressInput"
                                        v-model="
                                          webMembershipInfo.member.details
                                            .homeAddress
                                        "
                                      />
                                    </div>
                                  </li>
                                </ul>
                              </div>
                            </li>
                            <li class="field">
                              <!-- 필수 : required -->
                              <div class="title">상세주소</div>
                              <div class="content">
                                <ul class="row">
                                  <li class="item form-group">
                                    <div class="form">
                                      <input-text
                                        id="homeAdditionalAddressInput"
                                        name="homeAdditionalAddressInput"
                                        v-model="
                                          webMembershipInfo.member.details
                                            .homeAdditionalAddress
                                        "
                                      />
                                    </div>
                                  </li>
                                </ul>
                              </div>
                            </li>
                            <li class="field">
                              <!-- 필수 : required -->
                              <div class="title">접속차단</div>
                              <div class="content">
                                <ul class="row">
                                  <li class="item form-group">
                                    <!-- 포커스 : focus -->
                                    <ul class="check">
                                      <li>
                                        <label>
                                          <input
                                            type="radio"
                                            v-model="
                                              webMembershipInfo.member
                                                .webIdInformation.isIntercepted
                                            "
                                            name="isIntercepted"
                                            value="true"
                                          />
                                          <i></i>
                                          <div class="label">차단</div>
                                        </label>
                                      </li>
                                      <li>
                                        <label>
                                          <input
                                            type="radio"
                                            v-model="
                                              webMembershipInfo.member
                                                .webIdInformation.isIntercepted
                                            "
                                            name="isIntercepted"
                                            value="false"
                                          />
                                          <i></i>
                                          <div class="label">해제</div>
                                        </label>
                                      </li>
                                    </ul>
                                  </li>
                                </ul>
                              </div>
                            </li>
                          </ul>
                          <div class="border-left"></div>
                          <div class="border-right"></div>
                          <div class="border-top"></div>
                          <div class="border-bottom"></div>
                        </div>
                      </div>
                      <div class="border-left"></div>
                      <div class="border-right"></div>
                      <div class="border-top"></div>
                      <div class="border-bottom"></div>
                    </div>
                    <div class="body-caption">
                      ※ 비밀번호 미입력시 임의 비밀번호가 생성됩니다.<br />
                      임시 비밀번호는 회원정보 저장시 SMS로 전송됩니다.
                    </div>
                    <div class="body-box">
                      <div class="title">[SMS 문구 예시]</div>
                      <div>
                        <strong>{{ webMembershipInfo.member.name }}</strong> 님
                        웹 ID는
                        {{ displayWebId }}
                        비밀번호는 ******** 입니다.
                      </div>
                    </div>
                  </div>
                </section>
              </article>
            </div>
          </div>
        </div>
        <div class="windowFooter">
          <ul class="button">
            <li class="save keyColor">
              <erp-button
                  button-div="SAVE"
                  :ignore="isPopupOpened"
                  :is-shortcut-button="true"
                  :is-key-color="true"
                  v-on:click.native="onSaveClicked"
                  :disabled=" commonMixinIsButtonDisableByAuth('memberMngWebReg') || isSaveButtonDisabled"
              >
                저장
              </erp-button>
            </li>
            <li class="close">
              <erp-button
                  button-div="CLOSE"
                  :is-icon-custom="true"
                  @click.native="onCloseClicked">
                닫기
              </erp-button>
            </li>
          </ul>
        </div>
      </div>
    </ejs-dialog>
    <daum-postcode-popup
      ref="daumPostcodePopup"
      v-if="isDaumPostcodePopupOpen"
      :position="{ x: 'center', y: 'center' }"
      :isModal="true"
      @popupClose="daumPostcodePopupClosed"
      @popupConfirmed="daumPostcodePopupConfirmed"
    />
    <web-password-reset-popup
      v-if="isWebPasswordResetPopupOpen"
      ref="webPasswordResetPopup"
      :position="{ x: 'center', y: 'center' }"
      :isModal="true"
      @popupClosed="webPasswordResetPopupClosed"
      @popupConfirmed="webPasswordResetPopupConfirmed"
    ></web-password-reset-popup>
  </div>
</template>
<script>
import moment from "moment";
import {
  commonCodeGetComCodeDefaultValue,
  commonCodesGetCommonCode,
} from "@/utils/commonCodes";
import commonMixin from "@/views/layout/mixin/commonMixin";

import GolfErpAPI from "@/api/v2/GolfErpAPI";
import { getWebIdCheck, getHpNoCheck } from "@/api/webMember";
import { validateFormRefs, ValidType } from "@/utils/formUtil";
import { deepDiffs } from "@/utils/ObjectUtil";

import DaumPostcodePopup from "@/views/common/DaumPostcodePopup.vue";
import InputBirthday from "@/components/common/datetime/InputBirthday";
import WebPasswordResetPopup from "@/views/member-management/WebPasswordResetPopup";
import InputText from "@/components/common/text/InputText";
import confirmDialogMixin from "@/views/layout/mixin/messagePopupDialogMixin";
import ComponentTelephone from "@/components/ComponentTelephone";
import ErpButton from "@/components/button/ErpButton.vue";

export default {
  name: "webMemberInfoPopup",
  props: ["memberId", "membershipId"],
  components: {
    ComponentTelephone,
    InputText,
    DaumPostcodePopup,
    InputBirthday,
    WebPasswordResetPopup,
    ErpButton,
  },
  mixins: [commonMixin, confirmDialogMixin],
  mounted() {},
  computed: {
    changeWebMembershipInfo() {
      return deepDiffs(this.webMembershipInfoOrigin, this.webMembershipInfo);
    },
    displayWebId() {
      const webId = this.webMembershipInfo?.member?.webIdInformation?.id;

      if (["KAKAO", "NAVER"].includes(webId?.split(":")?.[0])) {
        return webId?.split(":")?.[0];
      } else {
        return webId;
      }
    }
  },
  data() {
    return {
      isWebPasswordResetPopupOpen: false,
      mutated: false,
      newFlag: true,
      isSaveButtonDisabled: false,
      commonCodeFields: { text: "comName", value: "comCode" },
      buttons: {
        browse: "등록",
      },
      currentWebId: "",
      currentHpNo: "",
      webMemberForm: {},
      rules: {
        nameInput: {
          required: true,
          maxLength: 100,
        },
        phoneNumberInput: {
          required: true,
        },
        webIdInput: {
          required: true,
          maxLength: 100,
        },
        inputBirthday: {
          required: true,
        },
        genderDropdown: {
          required: true,
        },
        emailInput: {
          type: ValidType.MAIL,
        },
        homePostalCodeInput: {
          required: true,
        },
        homeAddressInput: {
          required: true,
        },
      },
      isSendSMS: true,
      DropDownTreeListData: [],
      webMembershipInfo: this.getWebMembershipInfoDefaultValue(), // 회원정보 상세정보
      webMembershipInfoOrigin: this.getWebMembershipInfoDefaultValue(), // 수정 시 비교할 값
      webMemberInfoOptions: {
        // dropdownlist option값 등
        // 성별
        genderOptions: commonCodesGetCommonCode("SEX_CODE"),
        // SMS수신동의
        smsReceiptDivisionOptions: commonCodesGetCommonCode("SMS_RECPTN_DIV"),
      },
      animationSettings: { effect: "None" },
      isDaumPostcodePopupOpen: false,
    };
  },
  methods: {
    validateFormRefs,
    getWebMembershipInfoDefaultValue() {
      return {
        member: {
          id: null, // key : memberId
          name: null,
          phoneNumber: null,
          birthday: new moment().format("YYYY-MM-DD"),
          lunarCode: "SOLAR",
          gender: commonCodeGetComCodeDefaultValue("SEX_CODE"),
          smsReceiptDivision: commonCodeGetComCodeDefaultValue(
            "SMS_RECPTN_DIV"
          ),
          details: {
            email: null,
            allowReceiptEmail: null,
            homePostalCode: null,
            homeAddress: null,
            homeAdditionalAddress: null,
          },
          webIdInformation: this.getWebIdInformation(),
        },
      };
    },
    getWebIdInformation() {
      return {
        id: null,
        password: null,
        isIntercepted: false,
      };
    },
    onWebPasswordResetPopupOpen() {
      this.isWebPasswordResetPopupOpen = true;
      this.$nextTick(() => {
        this.$refs.webPasswordResetPopup.showPopup({
          isCreate: false,
          memberId: this.webMembershipInfo.member.id,
        });
      });
    },
    webPasswordResetPopupClosed() {
      this.isWebPasswordResetPopupOpen = false;
    },
    webPasswordResetPopupConfirmed(args) {
      this.isWebPasswordResetPopupOpen = false;

      this.mutated = true;
      if (args?.isDeleted) {
        this.$refs.webMemberInfoPopupDialog.hide();
      }
    },
    showPopup() {
      this.$refs.webMemberInfoPopupDialog.show();

      // 수정 팝업
      if (this.memberId && this.membershipId) {
        this.webMembershipInfo.member.id = this.memberId;
        this.webMembershipInfo.id = this.membershipId;
        this.loadData(
          this.webMembershipInfo.member.id,
          this.webMembershipInfo.id
        );
      }
    },
    /**
     * 우편번호 검색 클릭 이벤트
     */
    onPostCodeClick() {
      this.onDaumPostcodePopupOpen();
    },
    /**
     * 다음 우편번호 검색 창 오픈
     */
    onDaumPostcodePopupOpen() {
      this.isDaumPostcodePopupOpen = true;
      this.$nextTick(() => {
        this.$refs.daumPostcodePopup.showPopup();
      });
    },
    /**
     * 우편번호 검색 창이 닫길 때의 이벤트
     */
    daumPostcodePopupClosed: function () {
      this.isDaumPostcodePopupOpen = false;
    },
    /**
     * 다음 주소 검색 확인 이벤트
     * @param data 검색한 주소 데이터
     */
    daumPostcodePopupConfirmed: function (data) {
      this.webMembershipInfo.member.details.homePostalCode = data.zonecode;
      this.webMembershipInfo.member.details.homeAddress = data.address;
      this.daumPostcodePopupClosed();
    },
    async loadData(memberId, membershipId) {
      if (memberId && membershipId) {
        await this.setMemberDetail(memberId, membershipId);
      }
    },
    async loadDataAfterSavePatch(savedMember, savedText, errorText) {
      const savedMemberId = savedMember?.id;
      const savedMembershipId = savedMember?.memberships?.[0]?.id;

      if (savedMemberId && savedMembershipId) {
        this.infoToast(savedText);
        this.mutated = true;
        await this.loadData(savedMemberId, savedMembershipId);
      } else {
        this.errorToast(errorText);
      }
    },
    dialogClose() {
      this.$emit("close", { mutated: this.mutated });
    },
    onDialogClicked: function () {
      // TODO : 팝업 닫기 이벤트 변경
      // this.$emit('popupEvent', 'popupClicked', this.$props.popupKey, this.$props.value);
    },
    async setMemberDetail(memberId, membershipId) {
      try {
        const v2Membership = await GolfErpAPI.fetchMembership(membershipId);

        // webMember 관련 정보가 없으면 에러 메시지 후 팝업 닫기
        if (!v2Membership.member.webIdInformation) {
          this.errorToast("웹회원 정보가 없는 회원입니다");
          this.$refs.webMemberInfoPopupDialog.hide();
          return;
        }

        this.currentPhoneNumber = v2Membership.member.phoneNumber;
        this.currentWebId = v2Membership.member.webIdInformation.id;

        // 데이터 보정
        const defaultWebMembershiInfo = this.getWebMembershipInfoDefaultValue();
        const copiedV2Membership = JSON.parse(JSON.stringify(v2Membership));

        this.webMembershipInfo = {
          ...defaultWebMembershiInfo,
          ...copiedV2Membership,
          member: {
            ...defaultWebMembershiInfo.member,
            ...copiedV2Membership.member,
            details: {
              ...defaultWebMembershiInfo.member.details,
              ...copiedV2Membership.member.details,
            },
            webIdInformation: {
              ...defaultWebMembershiInfo.member.webIdInformation,
              ...copiedV2Membership.member.webIdInformation,
            },
          },
        };
        this.webMembershipInfoOrigin = JSON.parse(
          JSON.stringify(this.webMembershipInfo)
        );

        // 수정 화면임
        this.newFlag = false;
      } catch (error) {
        console.log("getMembershipDetail.err.===>", error);
      }
    },
    onSaveClicked() {
      if (this.validateFormRefs(this.rules)) {
        this.saveWebMemberInfo();
      }
    },
    isMemberDetailEmpty(member) {
      if (!member || !member.details) {
        return true;
      } else {
        const memberDetails = member.details;
        return (
          !memberDetails.email &&
          memberDetails.allowReceiptEmail === null &&
          !memberDetails.homePostalCode &&
          !memberDetails.homeAddress &&
          !memberDetails.homeAdditionalAddress
        );
      }
    },
    async saveWebMemberInfo() {
      if (this.newFlag) {
        const saveMember = {
          member: JSON.parse(JSON.stringify(this.webMembershipInfo.member)),
          isSendSMS: this.isSendSMS,
        };

        // member.details 값이 없으면 날린다
        if (this.isMemberDetailEmpty(saveMember.member)) {
          delete saveMember.member.details;
        }

        let savedMember;
        try {
          savedMember = await GolfErpAPI.createWebIdMember(saveMember);
        } catch (e) {
          if (e.getData() === "Error") {
            // errortoast가 interceptor에서 이미 뜬 경우, 뜨지 않도록
            this.errorToast("저장 중 오류가 발생하였습니다");
          }
          console.log("putMemberDetail.err.===>", e);
          return;
        }
        await this.loadDataAfterSavePatch(
          savedMember,
          this.$t("main.popupMessage.saved"),
          "저장 후 재조회에 실패하였습니다"
        );
      } else {
        const patchWebIdMemberData = this.changeWebMembershipInfo;
        if (!patchWebIdMemberData) {
          this.errorToast("수정할 데이터가 없습니다");
          return;
        }

        let patchedMember;
        try {
          patchedMember = await GolfErpAPI.patchWebIdMember(
            this.webMembershipInfo.member.id,
            patchWebIdMemberData
          );
        } catch (e) {
          if (e.getData() === "Error") {
            // errortoast가 interceptor에서 이미 뜬 경우, 뜨지 않도록
            this.errorToast("수정 중 오류가 발생하였습니다");
          }
          console.log("putMemberDetail.err.===>", e);
          return;
        }

        await this.loadDataAfterSavePatch(
          patchedMember,
          this.$t("main.popupMessage.patched"),
          "수정 후 재조회에 실패하였습니다"
        );
      }
    },
    async onWebIdBlur() {
      const webId = this.webMembershipInfo.member.webIdInformation.id;

      if (!this.currentWebId && !webId) {
        return;
      }

      if (this.currentWebId !== webId) {
        this.isSaveButtonDisabled = true;
        try {
          const { value: webIdCheckResult } = await getWebIdCheck(webId, true);
          if (webIdCheckResult > 0) {
            this.errorToast("같은 ID가 존재합니다.");
            this.webMembershipInfo.member.webIdInformation.id = null;
            this.$refs.webIdInput.focusIn();
            return;
          } else {
            this.infoToast("사용 가능한 ID입니다.");
          }
        } catch (e) {
          console.log("getWebIdCheck.err.===>", e);
        } finally {
          this.isSaveButtonDisabled = false;
        }
      }
    },
    async onHpNoBlur() {
      const phoneNumber = this.webMembershipInfo.member.phoneNumber;

      if (!this.currentPhoneNumber && !phoneNumber) {
        return;
      }
      if (this.currentPhoneNumber !== phoneNumber) {
        this.isSaveButtonDisabled = true;
        try {
          const { value: hpNoCheckResult } = await getHpNoCheck(
            phoneNumber,
            true
          );
          if (hpNoCheckResult > 0) {
            if (
              !(await this.confirm(
                "같은 핸드폰 번호가 존재합니다.<br>계속 진행하시겠습니까"
              ))
            ) {
              this.webMembershipInfo.member.phoneNumber = null;
              this.$refs.phoneNumberInput.focusIn();
              return;
            }
          }
        } catch (e) {
          console.log("getHpNoCheck.err.===>", e);
        } finally {
          this.isSaveButtonDisabled = false;
        }
      }
    },
    onCloseClicked() {
      this.$refs.webMemberInfoPopupDialog.hide();
    },
  },
};
</script>
