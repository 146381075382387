<template>
  <div>
    <ejs-dialog
      allowDragging="true"
      header="상세검색"
      ref="dialog"
      showCloseIcon="true"
      width="387"
      v-bind="dialogProps"
      @close="onDialogClose"
      @overlayClick="onDialogOverlayClick"
    >
      <div class="window lookupDetail-memberInformationRegistration">
        <div class="windowContent">
          <div class="content-wrapper">
            <div class="content-lookupDetail">
              <ul class="lookupDetail-condition">
                <li class="field">
                  <div class="title">
                    {{ labels.webMemberId }}
                  </div>
                  <ul class="content">
                    <li class="item">
                      <input-text v-model="privateFilterOptions.webMemberId" />
                    </li>
                  </ul>
                </li>
                <li class="field">
                  <div class="title">
                    {{ labels.endOfHpNumber }}
                  </div>
                  <ul class="content">
                    <li class="item">
                      <input-text
                        v-model="privateFilterOptions.endOfHpNumber"
                        placeholder="핸드폰 뒤 4자리"
                        maxlength="4"
                      />
                    </li>
                  </ul>
                </li>
                <li class="field">
                  <div class="title">
                    {{ labels.acquireDate }}
                  </div>
                  <ul class="content">
                    <li class="item">
                      <ul class="check">
                        <li>
                          <label>
                            <input type="checkbox" v-model="useAcquireDate" />
                            <i></i>
                            <div class="label">사용</div>
                          </label>
                        </li>
                      </ul>
                    </li>
                    <li class="item dateRange">
                      <input-date-range
                        type="lookupDetail-condition"
                        :disabled="!useAcquireDate"
                        v-model="inputAcquireDateRange"
                      />
                    </li>
                  </ul>
                </li>
                <li class="field">
                  <div class="title">
                    {{ labels.gender }}
                  </div>
                  <ul class="content">
                    <li class="item check">
                      <ul class="check">
                        <li
                          :key="code.comCode"
                          v-for="code in commonCodesOfGenders"
                        >
                          <label>
                            <input
                              type="radio"
                              :key="`radio_${code.comCode}`"
                              :id="code.comCode"
                              v-model="privateFilterOptions.gender"
                              :value="code.comCode"
                            />
                            <i></i>
                            <div
                              class="label"
                              :key="`label_radio_${code.comCode}`"
                              :for="code.comCode"
                            >
                              {{ code.comName }}
                            </div>
                          </label>
                        </li>
                      </ul>
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="windowFooter">
          <ul class="button">
            <li class="lookup keyColor">
              <erp-button
                  button-div="GET"
                  :is-shortcut-button="true"
                  @click.native="onSubmitClick"
              >
                조회
              </erp-button>
            </li>
            <li class="reset">
              <erp-button
                  button-div="DELETE"
                  :is-icon-custom="true"
                  @click.native="onResetClick">
                초기화
              </erp-button>
            </li>
            <li class="close">
              <erp-button
                  button-div="CLOSE"
                  :is-icon-custom="true"
                  @click.native="onCloseClick">
                닫기
              </erp-button>
            </li>
          </ul>
        </div>
      </div>
    </ejs-dialog>
  </div>
</template>

<script>
import moment from "moment";
import { commonCodesGetCommonCode } from "@/utils/commonCodes";
import InputText from "@/components/common/text/InputText";
import InputDateRange from "@/components/common/datetime/InputDateRange";
import messagePopupDialogMixin from "@/views/layout/mixin/messagePopupDialogMixin";
import ErpButton from "@/components/button/ErpButton.vue";

const DEFAULT_FILTER_OPTIONS = {
  endOfHpNumber: "",
  webMemberId: "",
  acquireDateFrom: "",
  acquireDateTo: "",
  gender: "",
};

export default {
  name: "SearchWebMembersFilterOptionsPopup",
  components: {
    InputText,
    InputDateRange,
    ErpButton,
  },
  props: {
    q: {
      type: String,
      default: () => "",
    },
    filterOptions: {
      type: Object,
      default: () => JSON.parse(JSON.stringify(DEFAULT_FILTER_OPTIONS)),
    },
    labels: {
      type: Object,
      default: null,
    },
  },
  mixins: [messagePopupDialogMixin],
  created() {
    if (
      this.filterOptions.acquireDateFrom ||
      this.filterOptions.acquireDateTo
    ) {
      this.useAcquireDate = true;
      this.inputAcquireDateRange = {
        from: this.filterOptions.acquireDateFrom,
        to: this.filterOptions.acquireDateTo,
      };
    }

    if (this.filterOptions.birthday) {
      this.useBirthday = true;
    }
  },
  data() {
    return {
      privateQ: this.q,
      privateFilterOptions: this.filterOptions,

      useAcquireDate: false,
      inputAcquireDateRange: {
        from: moment().add(-7, "day").format("YYYY-MM-DD"),
        to: moment().format("YYYY-MM-DD"),
      },
      useBirthday: false,
    };
  },
  computed: {
    dialogProps() {
      return {
        animationSettings: { effect: "None" },
        isModal: false,
        position: { x: "center", y: "center" },
        visible: false,
      };
    },

    commonCodesOfCorporationDivisions() {
      const corporationDivisions = commonCodesGetCommonCode("CPR_DIV");

      if (corporationDivisions.findIndex((obj) => obj.comCode === "") === -1) {
        corporationDivisions.unshift({
          comCode: "",
          comName: "전체",
          codeAbrv: "",
          defaultFlag: true,
        });
      }

      return corporationDivisions;
    },
    commonCodesOfMemberDivisions() {
      const memberDivisions = commonCodesGetCommonCode("MEMBER_DIV").filter(
        ({ comCode }) => !["NOM", "NOT"].includes(comCode)
      );

      memberDivisions.unshift({
        comCode: "",
        comName: "전체",
        codeAbrv: "",
        defaultFlag: true,
      });

      return memberDivisions;
    },
    commonCodesOfMemberGrades() {
      const memberGrades = commonCodesGetCommonCode("MEMBER_GRADE");

      memberGrades.unshift({
        comCode: "",
        comName: "전체",
        codeAbrv: "",
        defaultFlag: true,
      });

      return memberGrades;
    },
    commonCodesOfGroupCompanyCodes() {
      const groupCompanyCodes = commonCodesGetCommonCode("GROUP_COMPANY_CODE");

      groupCompanyCodes.unshift({
        comCode: "",
        comName: "전체",
        codeAbrv: "",
        defaultFlag: true,
      });

      return groupCompanyCodes;
    },
    commonCodesOfSmsReceiptDivisions() {
      const smsReceiptDivisions = commonCodesGetCommonCode("SMS_RECPTN_DIV");

      smsReceiptDivisions.unshift({
        comCode: "",
        comName: "전체",
        codeAbrv: "",
        defaultFlag: true,
      });

      return smsReceiptDivisions;
    },
    commonCodesOfMembershipStatuses() {
      const membershipStatuses = commonCodesGetCommonCode("MEMBERSHIP_STATUS");

      membershipStatuses.unshift({
        comCode: "",
        comName: "전체",
        codeAbrv: "",
        defaultFlag: true,
      });

      return membershipStatuses;
    },
    commonCodesOfGenders() {
      const genders = commonCodesGetCommonCode("SEX_CODE");

      genders.unshift({
        comCode: "",
        comName: "전체",
        codeAbrv: "",
        defaultFlag: true,
      });

      return genders;
    },
  },
  methods: {
    show() {
      this.$refs["dialog"].show();
    },
    hide() {
      this.$refs["dialog"].hide();
    },
    onDialogClose() {
      this.$emit("close");
    },
    onDialogOverlayClick() {
      this.hide();
    },
    onSubmitClick() {
      if (this.useAcquireDate) {
        this.privateFilterOptions.acquireDateFrom = this.inputAcquireDateRange.from;
        this.privateFilterOptions.acquireDateTo = this.inputAcquireDateRange.to;
      } else {
        this.privateFilterOptions.acquireDateFrom = null;
        this.privateFilterOptions.acquireDateTo = null;
      }

      if (!this.useBirthday) {
        this.privateFilterOptions.birthday = null;
      }

      this.$emit("submit", {
        q: this.privateQ,
        filterOptions: this.privateFilterOptions,
      });
    },
    onResetClick() {
      this.privateFilterOptions = JSON.parse(
        JSON.stringify(DEFAULT_FILTER_OPTIONS)
      );
    },
    onCloseClick() {
      this.hide();
    },
  },
};
</script>

<style scoped></style>
